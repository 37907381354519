import React, { useState, useEffect, RefObject } from 'react';
import { css } from '@emotion/react';

const Cursor = ({ navPrev, navNext }: {
    navPrev: RefObject<HTMLDivElement>;
    navNext: RefObject<HTMLDivElement>;
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [hidden, setHidden] = useState(true);
    const [direction, setDirection] = useState('');

    const onMouseLeave = () => {
        setHidden(true);
    };

    const onMouseEnter = (e: MouseEvent) => {
        const eventTarget = e.target as HTMLElement;

        setHidden(false);

        if (eventTarget?.getAttribute('direction') === 'prev') {
            setDirection('prev');
        } else {
            setDirection('next');
        }
    };

    const onMouseMove = (e: MouseEvent) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    useEffect(() => {
        const addEventListeners = () => {
            if (navPrev && navPrev.current) {
                navPrev.current.addEventListener('mousemove', onMouseMove);
                navPrev.current.addEventListener('mouseenter', onMouseEnter);
                navPrev.current.addEventListener('mouseleave', onMouseLeave);
            }

            if (navNext && navNext.current) {
                navNext.current.addEventListener('mousemove', onMouseMove);
                navNext.current.addEventListener('mouseenter', onMouseEnter);
                navNext.current.addEventListener('mouseleave', onMouseLeave);
            }
        };

        const removeEventListeners = () => {
            if (navPrev && navPrev.current) {
                navPrev.current.removeEventListener('mousemove', onMouseMove);
                navPrev.current.removeEventListener('mouseenter', onMouseEnter);
                navPrev.current.removeEventListener('mouseleave', onMouseLeave);
            }

            if (navNext && navNext.current) {
                navNext.current.removeEventListener('mousemove', onMouseMove);
                navNext.current.removeEventListener('mouseenter', onMouseEnter);
                navNext.current.removeEventListener('mouseleave', onMouseLeave);
            }
        };

        addEventListeners();

        return () => removeEventListeners();
    }, [navNext, navPrev]);

    return (
        <div
            css={css`
                width: 15px;
                height: 15px;
                position: fixed;
                transform: translate(-50%, -50%);
                pointer-events: none;
                z-index: 9999;
                ${hidden === true && `
                    display: none;
                `}
                ${direction === 'prev' && `
                    transform: translate(-50%, -50%) rotate(180deg);
                `}
            `}
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12.07 10.07"
            >
                <path d="M7.02 0L5.97 1.07l3.19 3.12H0v1.5h9.16L5.77 9l1.05 1.07 5.25-5.13L7.02 0z" />
            </svg>
        </div>
    );
};

export default Cursor;
