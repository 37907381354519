import React from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import styled from '@emotion/styled';

const StyledImage = styled(GatsbyImage)`
    img {
        transition: opacity .5s ease 0s!important;
    }
`;

function Image({ ...props }: GatsbyImageProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <StyledImage {...props} />;
}

export default Image;
