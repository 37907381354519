import React, { useContext, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { ThemeContext } from '~/context/ThemeContext';
import themes from '~/utils/themes';
import SEO from '~/components/Seo';
import Slider from '~/components/Slider/Slider';
import ProductForm from '~/components/ProductForm';
import {
    ProductSection,
    Product,
    ProductTitle,
    ProductDescription,
    breakpoints,
    TextIndent,
    ProductSubtitle,
} from '~/utils/styles';
import useScrollTop from '~/hooks/use-scroll-top';
import HTMLContent from '~/components/HTMLContent';
import { getProductMetaFieldValues } from '~/utils';
import Footer from '~/components/Footer';

const MobileSliderContainer = styled.div`
    @media ${breakpoints.lg} {
        display: none;
    }
`;

const DesktopSliderContainer = styled.div`
    display: none;

    @media ${breakpoints.lg} {
        display: block;
    }
`;

const ProductPage = ({ data }: any) => {
    const { theme, setTheme } = useContext(ThemeContext);
    const scrollTop = useScrollTop();
    const formSectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (formSectionRef?.current) {
            const current = formSectionRef.current as HTMLElement;
            const refBox = current.getBoundingClientRect();
            const refTop = refBox.top;

            if (refTop - 50 <= 0) {
                setTheme(themes.white);
            } else if (theme !== themes.productPage) {
                setTheme(themes.productPage);
            }
        }
    }, [scrollTop, theme, setTheme]);

    useEffect(() => {
        setTheme(themes.productPage);
    }, [setTheme]);

    const product = data.shopifyProduct;
    const metafields = getProductMetaFieldValues(product) || {};
    const { product_subtitle: productSubtitle } = metafields;

    return (
        <>
            <SEO title={product.title} description={product.description} />

            <MobileSliderContainer>
                <Slider
                    images={product.media}
                    title={product.title}
                    cover
                />
            </MobileSliderContainer>

            <ProductSection>
                <Product>
                    <DesktopSliderContainer>
                        <Slider
                            images={product.media}
                            title={product.title}
                            cover={false}
                        />
                    </DesktopSliderContainer>

                    <div ref={formSectionRef}>
                        <ProductTitle>{product.title}</ProductTitle>
                        {productSubtitle && (
                            <ProductSubtitle>{productSubtitle}</ProductSubtitle>
                        )}

                        <ProductForm
                            product={product}
                            initiallyExpanded
                        />

                        {product.descriptionHtml && (
                            <TextIndent>
                                <ProductDescription>
                                    <HTMLContent
                                        html={product.descriptionHtml}
                                    />
                                </ProductDescription>
                            </TextIndent>
                        )}
                    </div>
                </Product>

            </ProductSection>

            <Footer />
        </>
    );
};

export const query = graphql`
    query($handle: String!) {
        shopifyProduct(handle: { eq: $handle }) {
            id
            title
            handle
            productType
            description
            descriptionHtml
            metafields {
                namespace
                key
                value
            }
            shopifyId: storefrontId
            priceRange: priceRangeV2 {
                minVariantPrice {
                    amount
                    currencyCode
                }
                maxVariantPrice {
                    amount
                    currencyCode
                }
            }
            options {
                id: shopifyId
                name
                values
            }
            variants {
                id
                title
                availableForSale
                shopifyId
                selectedOptions {
                    name
                    value
                }
            }
            media {
                ... on ShopifyMediaImage {
                    id
                    image {
                        src
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default ProductPage;
