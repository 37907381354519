import styled from '@emotion/styled';
import { breakpoints } from '~/utils/styles';

export const Carousel = styled('div')`
position: relative;
width: 100%;

/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/*
* Customize
*/ 
.slick-slider {
    // border: 1px solid red;
}

.slick-slider:focus,
    .slick-track:focus {
    outline: none
}

.slick-arrow {
    appearance: none;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;

    position: absolute;
    top: 0;

    width: 50%;
    height: 100%;

    cursor: none;
    //opacity: 0.75;
}

.slick-prev {
    left: 0;
    z-index: 10;

    //background-color: green;
}

.slick-next {
    right: 0;
    z-index: 10;

    //background-color: blue;
}


.slick-slide {
    height: 65vh;
    min-height: 400px;
    max-height: 480px;

    div {
        height: 100%;
        outline: none;
    }

    @media ${breakpoints.lg} { 
        max-height: 500px;
    }
}

.gatsby-image-wrapper > div {
    display: none;
}
`;

export type SwipeDirection = 'left' | 'down' | 'right' | 'up' | string;

interface NavProps {
    direction: SwipeDirection;
}

export const Navigation = styled.div(({ direction }: NavProps) => `
  position: absolute;
  top: 0;
  z-index: 10;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: none;
  padding: 0 25px;
  box-sizing: border-box;
  color: white;
  ${direction === 'prev' ? 'left: 0;justify-content: flex-start;' : ''}
  ${direction === 'next' ? 'right: 0;justify-content: flex-end;' : ''}
`);

export const NavIcon = styled.div`
    @media ${breakpoints.lg} {
        display: none;
    }
`;

export const Counter = styled('div')`
  position: absolute;
  right: 2rem;
  bottom: -2rem;
`;
