import styled from '@emotion/styled';

const Svg = styled.svg`
    vertical-align: top;
`;

function ArrowRightIcon() {
    return (
        <Svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.41406" width="19.0719" height="2" transform="rotate(45 1.41406 0)" fill="currentColor" />
            <rect x="14.9248" y="13.4897" width="19.0738" height="2" transform="rotate(135 14.9248 13.4897)" fill="currentColor" />
        </Svg>
    );
}

export default ArrowRightIcon;
