import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import ArrowLeftIcon from '~/components/ArrowLeftIcon';
import ArrowRightIcon from '~/components/ArrowRightIcon';

import Img from '~/components/Image';
import Cursor from '~/utils/cursor';
import {
    Carousel,
    Navigation,
    NavIcon,
    // Counter,
} from './styles';

interface SliderProps {
    images: any[];
    cover: any;
    title: string;
}

interface SlickSlider {
    slickNext(): void;
    slickPause(): void;
    slickPlay(): void;
    slickPrev(): void;
    slickGoTo(slideNumber: number, dontAnimate?: boolean): void;
}

const isMobile = () => {
    if (typeof window !== 'undefined') {
        const ua = navigator.userAgent;
        return /Android|Mobi/i.test(ua);
    }

    return undefined;
};

interface GatsbyImage {
    src: string;
}

const loadImages = (images: GatsbyImage[]) => Promise.all(images.map((image) => {
    const { src } = image || {};

    if (src && typeof window !== 'undefined') {
        return new Promise((resolve, reject) => {
            const i = new Image();
            i.onload = () => resolve(i);
            i.onerror = () => reject(i);
            i.src = src;
        });
    }

    return null;
}));

const SliderComponent = ({ images, cover, title }: SliderProps) => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [, setSlideIndex] = useState(1);
    const navPrev = useRef(null);
    const navNext = useRef(null);
    const sliderRef = useRef(null);

    const next = () => {
        if (sliderRef?.current) {
            const slider: SlickSlider = sliderRef.current;
            slider.slickNext();
        }
    };

    const previous = () => {
        if (sliderRef?.current) {
            const slider: SlickSlider = sliderRef.current;
            slider.slickPrev();
        }
    };

    const settings = {
        arrows: isMobile() !== false,
        infinite: true,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        // nextArrow: <NextArrow ref={navNext} />,
        // prevArrow: <PrevArrow ref={navPrev} />,
        /*
        beforeChange: function (currentSlide, nextSlide) {
            console.log('before change', currentSlide, nextSlide);
        },
        */
        afterChange(currentSlide: number) {
            setSlideIndex(currentSlide + 1);
        },
    };

    useEffect(() => {
        const awaitImages = async () => {
            try {
                await loadImages(images);
            } catch (e) {
                console.log('error loading images');
            }
        };
        awaitImages();

        setImagesLoaded(true);
    }, [images, setImagesLoaded]);

    return (
        <Carousel>
            {imagesLoaded && (
                <>
                    {(!isMobile() && images.length > 1) && (
                        <Cursor
                            navPrev={navPrev}
                            navNext={navNext}
                        />
                    )}
                    {images.length > 1 && (
                        <>
                            <Navigation
                                direction="prev"
                                ref={navPrev}
                                onClick={previous}
                            >
                                <NavIcon>
                                    <ArrowLeftIcon />
                                </NavIcon>
                            </Navigation>

                            <Navigation
                                direction="next"
                                ref={navNext}
                                onClick={next}
                            >
                                <NavIcon>
                                    <ArrowRightIcon />
                                </NavIcon>
                            </Navigation>
                        </>
                    )}
                    <Slider
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...settings}
                        ref={sliderRef}
                    >
                        {images.map((image) => (
                            <div
                                key={image.id}
                            >
                                <Img
                                    style={{ height: '100%', width: '100%' }}
                                    imgStyle={{ objectFit: cover ? 'cover' : 'contain' }}
                                    image={image.image.gatsbyImageData}
                                    loading="eager"
                                    alt={title}
                                />
                            </div>
                        ))}
                    </Slider>
                </>
            )}

            {/* images.length > 1 &&
                <Counter>
                {slideIndex}/{images.length}
                </Counter>
            */}
        </Carousel>
    );
};

export default SliderComponent;
