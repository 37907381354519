import styled from '@emotion/styled';

const Svg = styled.svg`
    vertical-align: top;
`;

function ArrowLeftIcon() {
    return (
        <Svg width="15" height="27" viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="13.5107" y="26.9766" width="19.0719" height="2" transform="rotate(-135 13.5107 26.9766)" fill="currentColor" />
            <rect y="13.4868" width="19.0738" height="2" transform="rotate(-45 0 13.4868)" fill="currentColor" />
        </Svg>
    );
}
export default ArrowLeftIcon;
