import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export default function useScrollTop() {
    const [scrollTop, setScrollTop]: [number, (y: number) => void] = useState(0);

    const handleScroll = throttle(() => {
        setScrollTop(window.pageYOffset);
    }, 250);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return scrollTop;
}
